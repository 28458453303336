import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
import SearchSelect from "../../components/common/Search";
import { uploadFile } from '../../api/qiniuApI';
export default {
  name: "Sector",
  data: function data() {
    return {
      // 筛选
      filter: [{
        placeholder: '板块状态',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '正常'
        }, {
          id: '-1',
          name: '禁用'
        }]
      }, {
        placeholder: '板块名称',
        value: '',
        type: 'text',
        list: []
      }],
      sum: "",
      //类型循环的所有id加起来
      // 用户信息
      userInfo: this.Ls.getParseToken("nof_userInfo"),
      // List接口返回表格数据
      tableKey: 1,
      //表格的key
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 20,
      //每页个数
      // drawer抽屉-数据
      drawer: false,
      //是否显示弹框
      direction: "rtl",
      drawerState: "",
      drawerData: {
        name: "",
        client: [],
        rank: "",
        state: 1,
        icon: null
      },
      // 1、教师端 2、学生端 3、公用
      clientList: [{
        label: '教师端',
        value: 1
      }, {
        label: '学生端',
        value: 2
      }
      // { label: '公用', value: 3 },
      ],

      // dialog弹框-删除弹框
      delId: "",
      centerDialogVisible: false // 删除弹框显示隐藏
    };
  },

  watch: {},
  computed: {
    filtereClient: function filtereClient() {
      // 1、教师端 2、学生端 3、公用
      function formatClient(client) {
        return client == 1 ? '教师端' : client == 2 ? '学生端' : '公用';
      }
      return formatClient;
    }
  },
  components: {
    SearchSelect: SearchSelect
  },
  created: function created() {
    //获取数据
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var _this2 = this;
      this.service.axiosUtils({
        requestName: "sectorGetList",
        data: {
          // userId:this.userInfo.userId,
          // roleId:this.userInfo.roleId,
          pageSize: this.pageSize,
          pageIndex: this.currentPage
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this2.tableData = res.data.data;
            _this2.total = res.data.total;
            _this2.initType();
          } else {
            _this2.$message.error(res.message);
          }
        }
      });
    },
    //初始化type
    initType: function initType() {},
    //筛选功能
    search: function search(filterArr) {
      var _this3 = this;
      this.service.axiosUtils({
        requestName: "sectorGetList",
        data: {
          name: filterArr[1].value,
          state: filterArr[0].value,
          // userId:this.userInfo.userId,
          // roleId:this.userInfo.roleId,
          pageSize: this.pageSize,
          pageIndex: this.currentPage
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this3.tableData = res.data.data;
            _this3.total = res.data.total;
            _this3.initType();
          } else {
            _this3.$message.error(res.message);
          }
        }
      });
    },
    //重置
    reload: function reload() {
      this.filter[0].value = "";
      this.filter[1].value = "";
      this.initData();
    },
    // page-页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      this.initData();
    },
    // page-页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.initData();
    },
    // 添加函数
    addFun: function addFun() {
      this.drawerState = "add";
      this.drawer = true;
    },
    validateArray: function validateArray(rule, value, callback) {
      if (value && value.length > 0) {
        callback();
      } else {
        callback(new Error('请选择客户端类型2'));
      }
    },
    //客户端类型改变
    checkedItem: function checkedItem(value) {
      // this.client.length === 2 ? this.drawerData.client = 3 : this.drawerData.client = Number(this.client)
    },
    //修改课程图标
    changeFile: function changeFile(file, fileList) {
      var _this = this;
      _this.file = file.raw;
      var reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = function (e) {
        _this.drawerData.icon = this.result;
      };
      uploadFile({
        folderName: "section/icon",
        complete: function complete(res, url) {
          _this.drawerData.icon = url;
          window.loading.close();
        }
      }, _this.file);
    },
    // drawer抽屉-取消函数
    cancelClick: function cancelClick() {
      this.$refs["form"].resetFields();
      this.drawer = false;
    },
    // drawer抽屉-确定函数
    confirmClick: function confirmClick(formName) {
      var that = this;
      this.setPostData();
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var url = "";
          that.drawerData.userId = that.userInfo.userId;
          that.drawerData.roleId = that.userInfo.roleId;
          if (that.drawerState === "edit") {
            //修改
            url = "editSector";
          } else {
            //添加
            url = "addSector";
          }
          // 处理下数据
          var rqData = Object.assign({}, that.drawerData);
          rqData.client = rqData.client.length === 2 ? 3 : Number(rqData.client);
          that.service.axiosUtils({
            requestName: url,
            data: rqData,
            sucFn: function sucFn(res) {
              if (res.status === 1) {
                that.$toast.success(res.message);
                that.drawer = false;
                that.clearDrawerData();
                if (that.drawerState === "add") {
                  that.currentPage = 1;
                }
                that.$nextTick(function () {
                  that.$refs["form"].resetFields();
                });
                that.initData();
              } else {
                that.$toast.error(res.message);
              }
            }
          });
        }
      });
    },
    //传递的参数处理转换
    setPostData: function setPostData() {
      // this.drawerData.type=0;
    },
    //新建初始化数据
    clearDrawerData: function clearDrawerData() {
      this.drawerData = {
        name: "",
        client: [],
        rank: "",
        state: 1,
        icon: null
      };
      this.initType();
      this.$refs.form.resetFields();
    },
    //获取下拉
    resourcetypeGetAll: function resourcetypeGetAll() {
      var _this4 = this;
      this.service.axiosUtils({
        requestName: 'resourcetypeGetAll',
        data: {
          courseId: this.$route.query.courseId
        },
        sucFn: function sucFn(res) {
          _this4.resourcetypeDrop = res.data;
          console.log(res.data);
        }
      });
    },
    // table-修改正常/禁用状态
    editState: function editState(row) {
      var _this5 = this;
      var rowState = "";
      if (row.state === 1) {
        rowState = -1;
      } else {
        rowState = 1;
      }
      this.service.axiosUtils({
        requestName: "editSectorStatus",
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          id: row.id,
          state: rowState
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this5.$toast.success(res.message);
            if (row.state === 1) row.state = -1;else row.state = 1;
          } else {
            _this5.$toast.error(res.message);
          }
        }
      });
    },
    // table-中操作函数
    detail: function detail(type, val) {
      var _this6 = this;
      if (type === "edit") {
        this.drawerState = "edit";
        this.drawer = true;
        setTimeout(function () {
          var client = val.client === 3 ? [1, 2] : [val.client];
          _this6.drawerData = {
            id: val.id,
            name: val.name,
            client: client,
            rank: val.rank,
            state: val.state,
            icon: val.icon
          };
        }, 30);
      } else if (type === "del") {
        this.centerDialogVisible = true;
        this.delId = val.id;
      } else {}
    },
    // dialog弹框-删除弹框确定函数
    confirmFun: function confirmFun() {
      var _this7 = this;
      //删除当前的板块
      this.service.axiosUtils({
        requestName: "deleteSector",
        data: {
          id: this.delId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this7.$toast.success(res.message);
            _this7.delId = "";
            _this7.centerDialogVisible = false;
            _this7.initData();
          } else {
            _this7.$toast.error(res.message);
          }
        }
      });
    }
  }
};